<template>
  <div class='page about-us'>
    <div class='banner'>
      <img src='https://cdn.s777.club/web/about-banner.png'/>
      <h1>About Us</h1>
    </div>
    <div class='content'>
      <h2>Embark on an adventure with Super777 Club!</h2>
      <p>The word 'SUPER' inspires different things depending on where you are in the world. When you see it written on our website, however, it’s an amalgamation of all of them - perfectly represented by our mascot, SUP.</p>
      <p>SUP's a lil bit chubby like a Chinese bun. He loves games like the Sup Game Box. Feline features come from the kitten and he undertakes hard work too. On top of all that, he's lucky - a jewel or treasure to many people.</p>
      <p>He represents our enthusiastic team in every way, whether it's loving food, working hard, or delivering unparalleled digital games. Over 100 combined years of industry experience have reinforced our aim to make Online Casinos something beyond an experience - they're a journey. Come adventure with us to be constantly satisfied by regular content, and exciting offers.</p>
      <p>We cover everything, from slots and live dealers, to table and crypto games. Powered by cashapp and bitcoin payments, the journey is faster than ever before, and completely guided by 24/7 customer support.</p>
      <p>Super777 Club Casino: A Licensed Casino You Can Trust.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>
